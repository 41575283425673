import { NgModule } from '@angular/core';
import { createHttpLink, from, InMemoryCache } from '@apollo/client/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
// import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';
import { environment } from 'src/environments/environment';
import { HttpService } from './core/services/http.service';
import { RefreshTokenService } from './core/services/refresh-token.service';
import { throwError } from 'rxjs';
import { LocalStorageService } from './core/services/local-storage.service';
import { onError } from '@apollo/client/link/error';
import { HttpClientModule } from '@angular/common/http';
import { HttpLink } from 'apollo-angular/http';
import { AppModule } from './app.module';
import { AuthService } from './core/services/auth.service';
import { ApolloLink, Observable, FetchResult, Operation, NextLink } from '@apollo/client/core';
import { MsalService } from '@azure/msal-angular';



let unHandledError = false;
const url = environment.API_URL; // <-- add the URL of the GraphQL server here
let userData: any;
let token: any;
let authLink;
let errorLink;
export function createApollo( httpLink: HttpLink, _authService: AuthService,refreshTokenService: RefreshTokenService,cookieService: LocalStorageService,msalService:MsalService) {

      authLink = new ApolloLink((operation: Operation, forward: NextLink): Observable<FetchResult> => {
        return new Observable<FetchResult>(observer => {
          userData = cookieService.get('UserData');
          let subscriptionKey = environment.API_SUBSCRIPTION_KEY;
          let options: any = {};
          const activeAccount = msalService.instance.getAllAccounts()[0];
          // Retrieve session-id and other headers if available
          if (cookieService.get('session-id')) {
            options['x-session-id'] = cookieService.get('session-id');
          }
          if (operation.operationName === "schoolAdminLogoutAction" || operation.operationName === "switchSchoolAdmin") {
            options['refreshToken'] = cookieService.get('_refresh_token');
          }
          if(activeAccount)
          {
            cookieService.getSoucsAdminToken().then(val => {
              token = val ? `${val}` : undefined;
              options = {
                ...options,
                Authorization: token,
                'Ocp-Apim-Subscription-Key': subscriptionKey,
              };
              operation.setContext({ headers: options });
              // Call the next link in the middleware chain and subscribe to the response
              forward(operation).subscribe({
                next: (response) => observer.next(response),
                error: (err) => observer.error(err),
                complete: () => observer.complete(),
              });     
            })
          }
          else{
            if (userData) {
              token = `Bearer ${cookieService.get('_token')}`;             
            }
            options = {
              ...options,
              Authorization: token,
              'Ocp-Apim-Subscription-Key': subscriptionKey,
            };
            operation.setContext({ headers: options });
            // Call the next link in the middleware chain and subscribe to the response
            forward(operation).subscribe({
              next: (response) => observer.next(response),
              error: (err) => observer.error(err),
              complete: () => observer.complete(),
            });     
            
          }     
          
        });
      });

 errorLink = onError(
  ({ forward, graphQLErrors, networkError, operation }): any => {
    
    if (graphQLErrors && !unHandledError) {
      if (
        graphQLErrors.some((x:any) =>
          x.status == "401"
        )
      ) {
        return new Observable(observer => {
          refreshTokenService.refreshToken().subscribe({
            
            next: (result) => {
              if(result && result['data']){
                cookieService.set('_token',result['data']['refreshSchoolAccessToken']['token']);
                cookieService.set('_refresh_token',result['data']['refreshSchoolAccessToken']['refresh_token']);
              }
              if (userData) {
                token = cookieService.get('_token')
                 token = `Bearer ${token}`;
               } else {
                  
                    cookieService.getSoucsAdminToken().then((val)=>{
                      console.log(val);
                      
                      token = val ? `${val}` : undefined;

                    })
                  
                 }
              let subscriptionKey = environment.API_SUBSCRIPTION_KEY;
              // After refreshing the token, continue the original operation
              let options:any  =  {
                Authorization: token,
                'Ocp-Apim-Subscription-Key': subscriptionKey
              }
              if(cookieService.get('session-id'))
              {
                options = {...options,'x-session-id':cookieService.get('session-id')};
              }
              if(operation.operationName == "schoolAdminLogoutAction")
              {
                options = {...options,'refreshToken':cookieService.get('_refresh_token')};
      
              }
              if(operation.operationName == "switchSchoolAdmin")
              {
                options = {...options,'refreshToken':`Bearer ${cookieService.get('_refresh_token')}`};
      
              }
              operation.setContext({
                headers: options
              });
              forward(operation).subscribe({
                next: response => observer.next(response),
                error: err => observer.error(err),
                complete: () => observer.complete()
              });
            },
            error: err => {
              console.error('Error refreshing token:', err);
              observer.error(err);
            }
          }),
          err =>{
            console.log(err);
            
          }
        });
      } 

      unHandledError = true;
    } else {
      unHandledError = false;
    }
  }
);

  cookieService.getSoucsAdminToken().then((val)=>{  
    token = val;
  })  
  if (cookieService.get('adminType') === 'SCHOOL_ADMIN') {
    token = `Bearer ${cookieService.get('_token')}')}`;
  }
  return {
   link: from([authLink, errorLink,createHttpLink({ uri: url }) ]),
    cache: new InMemoryCache()
  };
}

@NgModule({
 imports: [ApolloModule, HttpClientModule],
  providers: [
    HttpService,
    AuthService,
    LocalStorageService,
    MsalService,    
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink,AuthService,RefreshTokenService,LocalStorageService,MsalService],
    },
  ],
})

 
export class GraphQLModule{}