import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { HttpService } from './http.service';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CustomErrorHandlerService } from './custom-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class RefreshTokenService {

  constructor(
    private httpService: HttpService,
    private http: HttpClient,
    private readonly _errorHandler: CustomErrorHandlerService,
  ) { }

  
   refreshToken(){
    const refreshTokenQuery = `mutation($refresh_token:String!){
    refreshSchoolAccessToken(refreshAccessTokenInput:{refresh_token:$refresh_token}){
    user {
      id,
      schoolid,
      name,
      email,
    
    },
    token,
    refresh_token,
    tokenExpire
  }
}`;    
    let refresh_token = this.httpService.getCookie('_refresh_token') || ''; 
    let subscriptionKey = environment.API_SUBSCRIPTION_KEY;
      let headers = new HttpHeaders({
        'Ocp-Apim-Subscription-Key': subscriptionKey
      });

    return this.http.post<any>(environment.API_URL, {query: refreshTokenQuery, variables:{
        "refresh_token": refresh_token
    }},{headers:headers} )
      .pipe(
        tap((result: any) => {
          if(result && result['errors'] && result['errors'].some((x:any) => x.status == "401" && result['errors'].some((x:any) => x.fatalError == "1002")) )
          {
                this._errorHandler.manageError(result['errors'][0], true);
            
          }
        }),
        catchError(error => {
          return throwError(error);
        })
      );    
  }
}
