import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { MenuListItemComponent } from './components/navigation/menu-list-item/menu-list-item.component';
import { NavigationComponent } from './components/navigation/navigation.component';

import { MaterialModule } from './module/material/material.module';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

@NgModule({
  declarations: [NavigationComponent, HeaderComponent, MenuListItemComponent, PageNotFoundComponent],
  exports: [NavigationComponent, HeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
  ],
  providers: [
  ],
})
export class SharedModule {}