<div class="container-fluid">
    <div class="row">
        <div class="col-1 offset-11">
            <mat-icon class="cancel-btn" (click)="cancel()">close</mat-icon>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 d-flex justify-content-center">
            <span class="font-weight-bold heading-text">{{pgtitle}}</span>
        </div>
    </div>
    <div class="row">
        <form [formGroup]="dismissForm">
            <div class="row">
                <div class="col-12">
                    <span class="color-gray">Select from the following with a written explanation, then report:</span>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <label class="font-bold">Choose a summary:</label>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                    <mat-radio-group class="custom-radio-group" aria-label="Select an option" #dismissType
                        formControlName="dismissType" name="dismissType">
                        <mat-radio-button *ngFor="let option of dismissTypeOptions" [value]="option.value">
                            {{option.type}} </mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="getFieldR('dismissType')?.invalid && getFieldR('dismissType').touched">
                        Please select an option.
                    </mat-error>
                </div>
            </div>
            
            <div class="row mt-3" *ngIf="getFieldR('dismissType').value">
                <div class="col-12">
                    <label class="color-gray">Provide explanation for dismissal:</label>
                    <mat-form-field class="top-margin-minus-10-px">
                        <textarea matInput maxlength="250" #dismissDescription formControlName="dismissDescription"
                        name="dismissDescription"></textarea>
                        <mat-hint align="end" class="font-italic p-1">{{dismissDescription.value.length}} / 250</mat-hint>
                        <mat-error *ngIf="getFieldR('dismissDescription')?.invalid && getFieldR('dismissDescription').touched">
                            Please provide the explanation.
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
           
            <div class="row mt-3" *ngIf="isbulkUpload">
                <div class="col-12">
                    <div class="alert alert-warning" role="alert">
                        <span style="display: inline-flex;">
                            <img alt='' style="margin-right: 5px;"
                            src="../../assets/images/svg/disclaimer-icon.svg">
                             Disclaimer
                        </span>
                        <br>
                        You have applied the same reason for dismissing all selected students.
                      </div>
                </div>
            </div>
        </form>
    </div>
    <div class="row button-div justify-content-center">
        <button class="report-to-soucs-btn" (click)="submit()">
            <span>Report to SOUCS</span>
        </button>
    </div>
</div>