export class User {
    constructor(
        private email: string,
        private name: string,
        private schoolid: number,
        private schoolName: string,
        private usertype: string,
        private isDeputyAdmin: number,
        private id: number
    ) { }

}