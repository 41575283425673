<div class="container-fluid">
    <div class="row float-right" *ngIf="!hideClose">
        <mat-icon (click)="cancel()">close</mat-icon>
    </div>
    <div class="row">
        <div class="col-md-12 d-flex justify-content-center">
            <svg *ngIf="icon=='cancel'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100" height="100" viewBox="0 0 100 100">
                <defs>
                  <clipPath id="clip-path">
                    <rect id="Rectangle_12168" data-name="Rectangle 12168" width="100" height="100" rx="50" transform="translate(153 288)" fill="#fff"/>
                  </clipPath>
                </defs>
                <g id="delet_account_icon" transform="translate(-153 -288)" clip-path="url(#clip-path)">
                  <g id="Group_20402" data-name="Group 20402" transform="translate(138.27 275.885)">
                    <path id="Polygon_25" data-name="Polygon 25" d="M50.384,0A42.857,42.857,0,0,1,87.4,21.263h0a42.857,42.857,0,0,1,0,43.189h0A42.857,42.857,0,0,1,50.384,85.714h-.768A42.857,42.857,0,0,1,12.6,64.452h0a42.857,42.857,0,0,1,0-43.189h0A42.857,42.857,0,0,1,49.616,0Z" transform="translate(42.857 0) rotate(30)" fill="#d0524e" opacity="0.239"/>
                    <path id="Polygon_26" data-name="Polygon 26" d="M41.57,0A35.714,35.714,0,0,1,72.464,17.8h0a35.714,35.714,0,0,1,0,35.837h0a35.714,35.714,0,0,1-30.894,17.8h-.284a35.714,35.714,0,0,1-30.894-17.8h0a35.714,35.714,0,0,1,0-35.837h0A35.714,35.714,0,0,1,41.287,0Z" transform="translate(46.709 10.472) rotate(30)" fill="#d0524e"/>
                    <g id="Group_20403" data-name="Group 20403" transform="translate(51.873 49.258)">
                      <rect id="Rectangle_584" data-name="Rectangle 584" width="34.911" height="1.455" transform="translate(1.028) rotate(45)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                      <rect id="Rectangle_585" data-name="Rectangle 585" width="34.911" height="1.455" transform="translate(25.714 1.028) rotate(135)" fill="#fff" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                    </g>
                  </g>
                </g>
              </svg>
              <svg *ngIf="icon=='alert'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="90" height="90" viewBox="0 0 90 90">
                <defs>
                  <clipPath id="clip-path">
                    <rect id="Rectangle_11168" data-name="Rectangle 11168" width="90" height="90" transform="translate(158 293)" fill="#fff"/>
                  </clipPath>
                </defs>
                <g id="delet_account_icon" transform="translate(-158 -293)" clip-path="url(#clip-path)">
                  <g id="Group_17598" data-name="Group 17598" transform="translate(138.27 275.885)">
                    <path id="Polygon_36" data-name="Polygon 36" d="M50.384,0A42.857,42.857,0,0,1,87.4,21.263h0a42.857,42.857,0,0,1,0,43.189h0A42.857,42.857,0,0,1,50.384,85.714h-.768A42.857,42.857,0,0,1,12.6,64.452h0a42.857,42.857,0,0,1,0-43.189h0A42.857,42.857,0,0,1,49.616,0Z" transform="translate(42.857 0) rotate(30)" fill="#d0524e" opacity="0.239"/>
                    <path id="Polygon_37" data-name="Polygon 37" d="M41.57,0A35.714,35.714,0,0,1,72.464,17.8h0a35.714,35.714,0,0,1,0,35.837h0a35.714,35.714,0,0,1-30.894,17.8h-.284a35.714,35.714,0,0,1-30.894-17.8h0a35.714,35.714,0,0,1,0-35.837h0A35.714,35.714,0,0,1,41.287,0Z" transform="translate(46.709 10.472) rotate(30)" fill="#d0524e"/>
                    <path id="Union_195" data-name="Union 195" d="M0,42.952a4.239,4.239,0,0,1,4.286-4.19,4.191,4.191,0,1,1,0,8.381A4.239,4.239,0,0,1,0,42.952ZM0,29.238V4.286a4.286,4.286,0,0,1,8.572,0V29.238a4.286,4.286,0,1,1-8.572,0Z" transform="translate(60.444 37.829)" fill="#fff"/>
                  </g>
                </g>
              </svg>
              <svg *ngIf="icon=='attempt'"  class="mb-4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="62" height="62" viewBox="0 0 62 62">
                <defs>
                  <clipPath id="clip-path">
                    <rect id="Rectangle_12501" data-name="Rectangle 12501" width="62" height="62" transform="translate(157 292)" fill="#fff"/>
                  </clipPath>
                </defs>
                <g id="attempt_remaning_icon" transform="translate(-157 -292)" clip-path="url(#clip-path)">
                  <g id="Group_21452" data-name="Group 21452" transform="translate(-997.968 22083.594)">
                    <path id="Polygon_40" data-name="Polygon 40" d="M35.269,0A30,30,0,0,1,61.182,14.884h0a30,30,0,0,1,0,30.232h0A30,30,0,0,1,35.269,60h-.538A30,30,0,0,1,8.818,45.116h0a30,30,0,0,1,0-30.232h0A30,30,0,0,1,34.731,0Z" transform="translate(1215.967 -21795.594) rotate(90)" fill="#d0524e" opacity="0.239"/>
                    <path id="Polygon_41" data-name="Polygon 41" d="M29.1,0A25,25,0,0,1,50.725,12.457h0a25,25,0,0,1,0,25.086h0A25,25,0,0,1,29.1,50h-.2A25,25,0,0,1,7.275,37.543h0a25,25,0,0,1,0-25.086h0A25,25,0,0,1,28.9,0Z" transform="translate(1210.967 -21789.594) rotate(90)" fill="#d0524e"/>
                    <g id="security-time-svgrepo-com" transform="translate(1169.558 -21777.592)">
                      <path id="Path_38224" data-name="Path 38224" d="M14.112,2.346,6.569,5.152A5.21,5.21,0,0,0,3.41,9.682V20.829a7.274,7.274,0,0,0,2.615,5.161l6.5,4.816a6.923,6.923,0,0,0,7.77,0l6.5-4.816a7.274,7.274,0,0,0,2.615-5.161V9.682a5.21,5.21,0,0,0-3.159-4.531L18.708,2.346A7.743,7.743,0,0,0,14.112,2.346Z" transform="translate(0)" fill="#fff"/>
                      <path id="block" d="M7,.25A6.737,6.737,0,0,1,11.951,2.3,6.75,6.75,0,0,1,14,7.25,6.75,6.75,0,0,1,11.951,12.2,6.737,6.737,0,0,1,7,14.25,6.737,6.737,0,0,1,2.049,12.2,6.751,6.751,0,0,1,0,7.25,6.748,6.748,0,0,1,2.049,2.3,6.739,6.739,0,0,1,7,.25Zm-5.59,7A5.455,5.455,0,0,0,2.59,10.7l7.836-7.887A5.426,5.426,0,0,0,6.984,1.63,5.36,5.36,0,0,0,3.049,3.274,5.423,5.423,0,0,0,1.41,7.25Zm5.574,5.62a5.4,5.4,0,0,0,3.967-1.643A5.425,5.425,0,0,0,12.59,7.25,5.455,5.455,0,0,0,11.41,3.8L3.574,11.687A5.38,5.38,0,0,0,6.984,12.87Z" transform="translate(9.41 9.748)" fill="#d0524e"/>
                    </g>
                  </g>
                </g>
              </svg>
              <svg  *ngIf="icon=='lock'" class="mb-4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="62" height="62" viewBox="0 0 62 62">
                <defs>
                  <clipPath id="clip-path">
                    <rect id="Rectangle_12502" data-name="Rectangle 12502" width="62" height="62" transform="translate(157 292)" fill="#fff"/>
                  </clipPath>
                </defs>
                <g id="lock_account_icon" transform="translate(-157 -292)" clip-path="url(#clip-path)">
                  <g id="Group_21453" data-name="Group 21453" transform="translate(-997.968 22083.594)">
                    <path id="Polygon_42" data-name="Polygon 42" d="M35.269,0A30,30,0,0,1,61.182,14.884h0a30,30,0,0,1,0,30.232h0A30,30,0,0,1,35.269,60h-.538A30,30,0,0,1,8.818,45.116h0a30,30,0,0,1,0-30.232h0A30,30,0,0,1,34.731,0Z" transform="translate(1215.967 -21795.594) rotate(90)" fill="#d0524e" opacity="0.239"/>
                    <path id="Polygon_43" data-name="Polygon 43" d="M29.1,0A25,25,0,0,1,50.725,12.457h0a25,25,0,0,1,0,25.086h0A25,25,0,0,1,29.1,50h-.2A25,25,0,0,1,7.275,37.543h0a25,25,0,0,1,0-25.086h0A25,25,0,0,1,28.9,0Z" transform="translate(1210.967 -21789.594) rotate(90)" fill="#d0524e"/>
                    <g id="security-time-svgrepo-com" transform="translate(1169.558 -21777.592)">
                      <path id="Path_38225" data-name="Path 38225" d="M14.112,2.346,6.569,5.152A5.21,5.21,0,0,0,3.41,9.682V20.829a7.274,7.274,0,0,0,2.615,5.161l6.5,4.816a6.923,6.923,0,0,0,7.77,0l6.5-4.816a7.274,7.274,0,0,0,2.615-5.161V9.682a5.21,5.21,0,0,0-3.159-4.531L18.708,2.346A7.743,7.743,0,0,0,14.112,2.346Z" transform="translate(0)" fill="#fff"/>
                      <path id="lock-svgrepo-com" d="M5.875,7.686c.295-.019.658-.019,1.125-.019h4c.467,0,.83,0,1.125.019m-6.25,0a2,2,0,0,0-.851.2,1.942,1.942,0,0,0-.819.874A5.279,5.279,0,0,0,4,10.867V11.8a5.279,5.279,0,0,0,.2,2.108,1.942,1.942,0,0,0,.819.874A4.386,4.386,0,0,0,7,15h4a4.387,4.387,0,0,0,1.976-.218,1.942,1.942,0,0,0,.819-.874A5.279,5.279,0,0,0,14,11.8v-.933a5.279,5.279,0,0,0-.2-2.108,1.942,1.942,0,0,0-.819-.874,2,2,0,0,0-.851-.2m-6.25,0V6.333A3.234,3.234,0,0,1,9,3a3.234,3.234,0,0,1,3.125,3.333V7.686" transform="translate(7.41 7.998)" fill="none" stroke="#d0524e" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    </g>
                  </g>
                </g>
              </svg>
        </div>
        <div class="gutter-space" *ngIf="pgtitle"></div>
        <div class="col-md-12 d-flex justify-content-center">
            <span class="message-font">{{message}} </span>
        </div>
    </div>
    <div class="gutter-space"></div>
    <ng-container *ngIf="dialogType === 'yesNo'">
        <div class="row button-div justify-content-center">
            <button class="app-btn unfilled large" (click)="cancel()">No </button>
            <button class="app-btn filled green-bg large" (click)="save()">Yes</button>
        </div>
    </ng-container>

    <ng-container *ngIf="dialogType === 'confirm'">
        <div class="row button-div justify-content-center">
            <button class="app-btn filled green-bg large custom-btn" (click)="save()">Confirm</button>
        </div>
    </ng-container>
    <ng-container *ngIf="dialogType === 'ok'">
      <div class="row button-div justify-content-center">
          <button class="app-btn filled green-bg large custom-btn" (click)="save()">Ok</button>
      </div>
  </ng-container>

</div>