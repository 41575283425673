<div class="container-fluid">
    <div class="row">
        <div class="col-md-12 d-flex justify-content-center">
            <span class="font-weight-bold heading-text">Re-Send Attempt Failed</span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12" style="padding-right: 0px;">
            <br>
            <span class="error-msg">
                <mat-icon>error_outline</mat-icon>
                School admin details are missing in one or more selected schools.</span>
            <div class="table-div">
                <table aria-describedby="adminUserList" mat-table [dataSource]="dataSource" matSort>
                    <!--School Name Column -->
                    <ng-container matColumnDef="school_name">
                        <th id="school_name" mat-header-cell *matHeaderCellDef> School Name </th>
                        <td mat-cell *matCellDef="let element">
                            <a [routerLink]="'/school-management/school-edit/' + element.school_id"
                                routerLinkActive="active" target="_blank" rel="bookmark">
                                {{element.school_name}}
                            </a>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </div>
    <div class="row d-flex justify-content-center">
        <button class="close-btn" (click)="cancel()">
            <span>Close</span>
        </button>
    </div>
</div>