import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeLayoutComponent } from './layout/home-layout/home-layout.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { MsalRedirectComponent } from '@azure/msal-angular';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/home',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
     component: HomeLayoutComponent,
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'login/callback',
    component: MsalRedirectComponent
  },
  {
    path: 'logout/callback',
    component: MsalRedirectComponent,
  },
  {
    path: '404', component: PageNotFoundComponent
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
