
<div class="container">
    <span class="errorcode">404</span><br>
    <br>
    <span class="errormsg" >Page Not Found</span><br><br>
     <span class="errordetails">Sorry! We couldn't find the page that you're looking for. </span>
<br><br>
     <a class="buton" (click)="goBack()">Back</a>
</div>


