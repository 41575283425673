import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from './local-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Apollo, gql } from 'apollo-angular';
import { DialogsService } from 'src/app/core/services/dialog-service';
import { environment } from 'src/environments/environment';
import { MsalService } from '@azure/msal-angular';
const logoutQuery = gql`
mutation schoolAdminLogoutAction($schoolAdminId:Float!,$soucsAdminEmail:String!){
  schoolAdminLogoutAction(schoolAdminId:$schoolAdminId,soucsAdminEmail:$soucsAdminEmail){
    id
  }
}`;

@Injectable({
  providedIn: 'root'
})
export class CustomErrorHandlerService {
  displayPopup = true;

  constructor(
    private readonly _toastr: ToastrService,
    private readonly _router: Router,
    private readonly _localStorage: LocalStorageService,
    private cookieService:CookieService,
    private spinner: NgxSpinnerService,
    public _apollo: Apollo,
    private readonly _dialogsService: DialogsService,
    private msalService: MsalService
  ) { 
  }

  manageError(error, isShowToasterMsg: Boolean = false) {
    
    // let errorTitle = error['graphQLErrors'][0]?.message;
    let errorTitle = '';
    let errorDetail = '';
    let errorStatus;
    if (error?.graphQLErrors?.length > 0) {
      errorStatus = error.graphQLErrors[0]?.status;
      isShowToasterMsg = !(error.graphQLErrors[0]?.fatalError == '1001');
      if (error.graphQLErrors[0]?.error?.length > 0) {
        let errorMsg = JSON.stringify(error.graphQLErrors[0].error);

        // check if first and last character is an array symbol.
        if (errorMsg.slice(0, 1) === '[' && errorMsg.slice(-1) === ']') {
          errorDetail = error.graphQLErrors[0].error[0];
        } else {
          errorDetail = error.graphQLErrors[0].error;
        }
        if (errorDetail?.toLowerCase()?.indexOf('forbidden') >= 0 || errorDetail?.toLowerCase() === 'unauthorized') {
          errorDetail = '';
        }
      }
    }

    if (isShowToasterMsg && errorDetail) {
      this._toastr.error(errorDetail, errorTitle);
    }

    
    if(errorStatus === 409){   
      let isSchoolAdmin = this._localStorage.isSchoolAdmin();
      let isSoucsAdmin = this._localStorage.isSoucsAdmin();
        if(error.graphQLErrors[0]?.fatalError == '1001')
        {   
          if(this.displayPopup)
        {
        const pgtitle = '';
        const message = `You have reached the maximum concurrent session limit. You will be logged out of this session.`;
          this.displayPopup = false;
          this._localStorage.set('concurrent-login',true);
          this._dialogsService
              .confirmationDialogPopUp(pgtitle, message,'ok')
              .subscribe((res) => {
                if(res)
                {
                  if(isSchoolAdmin) {
                    let userData = this._localStorage.get(('UserData'));
                    let schoolAdminId = JSON.parse(userData)['id'];    
                    this.schoolAdminLogout(schoolAdminId);
                  }
                  if(isSoucsAdmin){this.soucsAdminLogout();}
                  this.displayPopup = true;
                }    
              });
              }
        }
        else{          
          if(isSchoolAdmin){
            let userData = this._localStorage.get(('UserData'));
            let schoolAdminId = JSON.parse(userData)['id'];    
            this.schoolAdminLogout(schoolAdminId);
          }
          if(isSoucsAdmin){this.soucsAdminLogout();}
        }
     

    }
    if ((errorStatus === 403)) {      
      let isSchoolAdmin = this._localStorage.isSchoolAdmin();
      if(isSchoolAdmin)
      {      
        this._localStorage.removeAll();
        this._router.navigateByUrl('/auth/home');
        
      }     
    }
    if ((errorStatus === 422)) {      
      let isSoucsAdmin = this._localStorage.isSoucsAdmin(); 
      if(isSoucsAdmin)
      {      
          this.soucsAdminLogout();
      }     
    }
    
    if ((error && error.status === 401 && error.fatalError =='1002')) { 

      let isSchoolAdmin = this._localStorage.isSchoolAdmin();
      let isSoucsAdmin = this._localStorage.isSoucsAdmin(); 
    
            if(isSchoolAdmin){
              this._toastr.success('Logged out successfully');
              this._localStorage.remove('UserData');
              this._localStorage.removeAll();
              window.location.reload();           
            }
            if(isSoucsAdmin){this.soucsAdminLogout();}

         
    }
  }
  schoolAdminLogout(schoolAdminId)
  {
    this.spinner.show();
          this._apollo
            .mutate({
              mutation: logoutQuery,
              variables: {schoolAdminId:schoolAdminId,soucsAdminEmail:''},
              fetchPolicy: 'no-cache'
            }).subscribe(() => {
              this.spinner.hide();
              this._toastr.success('Logged out successfully');
              this._localStorage.remove('UserData');
              this._localStorage.removeAll();
              window.location.reload();             
            },(error)=>{ 
              this._localStorage.remove('UserData');
              this._localStorage.removeAll();
              this.spinner.hide();
              this._router.navigateByUrl('/auth/home')
              
              this.displayPopup = true;});
  }
  async soucsAdminLogout(){
    this.spinner.show();
    const allAccounts = this.msalService.instance.getAllAccounts()
    if(allAccounts.length)
    {
      this._apollo
        .mutate({
          mutation: logoutQuery,
          variables: {schoolAdminId:0,soucsAdminEmail:allAccounts[0].username},
          fetchPolicy: 'no-cache'
        }).subscribe(({ data }) => {
          this.spinner.hide();
          this.msalService.logoutRedirect({account:allAccounts[0]});
          this._localStorage.removeAll();
          this._toastr.success('Logged out successfully');
          window.location.reload();
        }, error => {
          this.spinner.hide();
          this.msalService.logoutRedirect({account:allAccounts[0]});
          this._localStorage.removeAll();
          this._toastr.success('Logged out successfully');
        });
    }
    else{
      this.msalService.logoutRedirect();
      this._localStorage.removeAll();
      this._toastr.success('Logged out successfully');
    }
  }

}
